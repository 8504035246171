import Projects from '../data/projets.json';
import ShowProjectItem from './common/projectItem';
import React from 'react';
export default function ProjectsComponent() {
  return (
    <main className="mainProjects">
      <h1>Projets de Développement</h1>
      <section className="homeProjects">
        <ProjectList />
      </section>
    </main>
  );
}

function ProjectList() {
  let ProjectGeneration = Projects.map((project, index) =>
    ShowProjectItem(project, index, Projects)
  );
  return ProjectGeneration;
}
