/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { API_ROUTES } from '../data/constants';
import Socials from '../data/social.json';
import ShowSocialLogo from './common/socialcomponents';

export default function ContactComponent() {
  let LogoGeneration = Socials.map((social, index) =>
    ShowSocialLogo(social, index, Socials)
  );
  return (
    <main className="contactMain">
      <h1>Contact</h1>

      <section className="contactFormSection">
        <h2>Contactez-moi</h2>
        <CreateContactForm />
      </section>

      <h2>Réseaux Sociaux</h2>
      <section className="socialSection">{LogoGeneration}</section>
    </main>
  );
}

function CreateContactForm() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const object = event.target.object.value;
    const body = event.target.body.value;
    const formData = { email, object, body };

    const PostMessage = await fetch(`${API_ROUTES.CONTACT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(formData),
    });

    const data = await PostMessage.json();

    if (!PostMessage.ok) {
      throw new Error(data.message);
    }
    console.log(data);
    return MessageSent();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="contactForm"
    >
      <label
        htmlFor="email"
        className="formLabel"
      >
        <p>Email</p>
        <input
          className="input"
          type="email"
          id="email"
          name="email"
          required={true}
        />
      </label>
      <label
        htmlFor="object"
        className="formLabel"
      >
        <p>Objet</p>
        <input
          className="input"
          type="text"
          id="object"
          name="object"
          required={true}
        />
      </label>
      <label
        htmlFor="body"
        className="formLabel"
      >
        <p>Votre Message</p>
        <textarea
          className="input inputMessage"
          type="textarea"
          id="body"
          name="body"
          required={true}
        />
      </label>
      <button
        type="submit"
        className="submitBtn"
      >
        Envoyer
      </button>
    </form>
  );
}

function MessageSent() {
  console.log('le message a été envoyé');
  const contactForm = document.getElementsByClassName('contactForm')[0];
  const contactDiv = contactForm.parentElement;

  contactForm.remove();
  const thankYouNote = document.createElement('div');
  thankYouNote.setAttribute('class', 'messageSent');
  thankYouNote.innerHTML = `<p>Merci pour votre contact. Je reviens rapidement vers vous</p> <a class="linkBackHome" href="/">Retourner à l'accueil</a>`;
  contactDiv.appendChild(thankYouNote);
}
