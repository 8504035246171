import Logo from '../files/zeflogo.png';

export default function Footer() {
  return (
    <footer>
      <img
        src={Logo}
        alt="logo Zefjaohaiozer"
        className="logoFooter"
      ></img>
    </footer>
  );
}
