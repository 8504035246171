import Projects from '../data/projets.json';
import { useLocation } from 'react-router-dom';
import FetchImages from './common/fetchimages';
import Technologies from '../data/technologies.json';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function ProjectPageComponent() {
  return (
    <React.Fragment>
      <ProjectDescription />
    </React.Fragment>
  );
}

function ProjectDescription() {
  const currentProjectId = useLocation().pathname.split('/')[2];

  const currentProjectObject = Projects.find(
    (project) => project.id === currentProjectId
  );
  return ProductDetails(currentProjectObject);
}

function ProductDetails(i) {
  const projectName = i.name;
  const projectDescription = i.projectDesc;
  //   const projectId = i.id;
  // eslint-disable-next-line
  const projectDetails = i.projectDetails;
  const projectUrl = i.projectUrl;
  const projectStatus = i.status;
  const projectImg = [];
  const projectRes = i.responsive;
  const projectDuration = i.duration;

  FetchImages(i, projectImg);

  return (
    <main className="mainProjectPage">
      <div className="linkProjectDiv">
        <ReturnProjectsArrow responsive={projectRes} />
        <a
          href={projectUrl}
          className="projectUrl"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt={projectName}
            src={projectImg}
            className="projectMini"
          ></img>
        </a>
      </div>
      <div className="projectNameAndDescDiv">
        <h1 className="projectName">{projectName}</h1>
        <h2 className="projectDescription"> {projectDescription}</h2>
      </div>
      <div className="projectDetailsAndTechDiv">
        <p className="projectDetails">{projectDetails}</p>
        <h2 className="usedTechTitle">Technologies Employées</h2>
        <ul className="usedTechList">
          {i.technologies.map((technology, index) =>
            UsedTech(technology, index)
          )}
        </ul>
      </div>
      <div className="projectStatusDiv">
        {projectDuration > 1 ? (
          <h2 className="projectStatus">
            Ce Projet est {projectStatus}, et il représente {projectDuration}{' '}
            semaines de travail.
          </h2>
        ) : (
          <h2 className="projectStatus">
            Ce Projet est {projectStatus}, son développement a duré{' '}
            {projectDuration} semaine.
          </h2>
        )}
      </div>
    </main>
  );
}
function UsedTech(i, k) {
  const techlogo = [];
  if (FindTechLogo(i, techlogo) === false) {
    return (
      <li
        key={k}
        className={`techItem ${i}`}
      >
        {i}
      </li>
    );
  } else {
    const techImg = require(`../files/techlogos/${techlogo[0]}`);
    if (techImg !== null) {
      return (
        <li
          key={k}
          className={`techItem ${i}`}
        >
          {i}
          <img
            src={techImg}
            alt={i}
            className="techLogo"
          ></img>
        </li>
      );
    } else {
      console.log('techImg est Null');
    }
  }
}

async function FindTechLogo(i, j) {
  const techLogo = Technologies.find((tech) => tech.id === i).logoImg;
  if (techLogo) {
    j.push(techLogo);
    return j;
  } else {
    return false;
  }
}

function ReturnProjectsArrow(i) {
  const Responsive = i.responsive;
  const desktopScreen = UpdateWindowSize();

  return (
    <div className="navigationHelp">
      <a
        href="/projects"
        className="backToProjectsLink"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        Retour aux Projets
      </a>
      <div className="siteVisit siteVisitItem">
        {Responsive || desktopScreen ? (
          <p className="siteVisitInvite siteVisitItem">
            {' '}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="siteVisitItem"
            />{' '}
            Visitez le Site !
          </p>
        ) : (
          <p className="nonResponsive siteVisitItem">
            {' '}
            Site non adapté aux mobiles
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="siteVisitItem"
            />
          </p>
        )}
      </div>
    </div>
  );
}

function UpdateWindowSize() {
  return window.innerWidth > 989 ? true : false;
}
