import Jobs from '../data/professionalexp.json';
import ShowJobItem from './common/jobItem';
import React from 'react';

export default function ExperienceComponent() {
  return (
    <main className="jobsMain">
      <h1>Expériences Professionnelles</h1>
      <section className="homeJobs">
        <JobList />
      </section>
    </main>
  );
}

function JobList() {
  let JobGeneration = Jobs.map((job, index) => ShowJobItem(job, index, Jobs));
  return JobGeneration;
}
