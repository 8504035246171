import FetchImages from './fetchimages';

export default function ShowSocialLogo(i, k, m) {
  const socialLogo = [];
  m.map((social, index) => FetchImages(social, socialLogo));

  return (
    <a
      key={k}
      href={`${i.url}`}
      className="socialLink"
      target="_blank"
      rel="noreferrer"
    >
      {' '}
      <img
        className="logoImg"
        src={socialLogo[k]}
        alt={i.name}
      ></img>
    </a>
  );
}
