import FetchImages from './fetchimages';

export default function ShowEducationItem(i, k, m) {
  const educationImg = [];
  m.map((school, index) => FetchImages(school, educationImg));

  return (
    <section
      className="schoolSection"
      key={k}
    >
      {' '}
      <div className="firstSchoolDiv">
        <h2 className="trainingTitle">{i.trainingTitle}</h2>
        <img
          className="schoolImg"
          src={educationImg[k]}
          alt={i.trainingTitle}
        ></img>
        <div className="schoolDatesDiv">
          <h3>Dates : </h3>
          <p>{i.dates}</p>
        </div>
        <div className="schoolMissionsDiv">
          <h3>Missions : </h3>
          {i.trainingDetails.map((mission, index) =>
            SchoolDescription(mission, index)
          )}
        </div>
      </div>
      <div className="secondSchoolDiv">
        <a
          href={i.schoolUrl}
          target="_blank"
          rel="noreferrer"
          className="schoolLink"
        >
          Site de l'école
        </a>
      </div>
    </section>
  );
}

function SchoolDescription(i, k) {
  return (
    <p
      key={k}
      className={`SchoolDescription ${i}`}
    >
      {i}
    </p>
  );
}
