import ShowEducationItem from './common/educationItem';
import Schools from '../data/education.json';
import React from 'react';

export default function EducationComponent() {
  return (
    <main className="schoolsMain">
      <h1>Diplômes</h1>
      <section className="homeSchools">
        <SchoolList />
      </section>
    </main>
  );
}

function SchoolList() {
  let SchoolGeneration = Schools.map((school, index) =>
    ShowEducationItem(school, index, Schools)
  );
  return SchoolGeneration;
}
