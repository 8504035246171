import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { NONCE } from './App';

PiwikPro.initialize(
  'd28003b6-6db6-4904-984b-505f3df4fc97',
  'https://entalimay.containers.piwik.pro',
  NONCE
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
