import FetchImages from './fetchimages';

export default function ShowJobItem(i, k, m) {
  const jobImg = [];
  m.map((job, index) => FetchImages(job, jobImg));

  return (
    <section className="jobSection">
      {' '}
      <div className="firstJobDiv">
        <h2 className="jobTitle">{i.jobTitle}</h2>
        <img
          className="jobImg"
          src={jobImg[k]}
          alt={i.jobTitle}
        ></img>
        <div className="jobDatesDiv">
          <h3>Dates : </h3>
          <p>{i.dates}</p>
        </div>
        <div className="jobMissionsDiv">
          <h3>Missions : </h3>
          <ul className="jobMissionsList">
            {i.jobDetails.map((mission, index) => JobMissions(mission, index))}
          </ul>
        </div>
      </div>
      <div className="secondJobDiv">
        <div className="jobToolsDiv">
          <h3>Outils :</h3>
          <ul className="usedToolsList">
            {i.tools.map((tool, index) => UsedTools(tool, index))}
          </ul>
        </div>
        <a
          href={i.companyUrl}
          target="_blank"
          rel="noreferrer"
          className="joblink"
        >
          Site de l'entreprise
        </a>
      </div>
    </section>
  );
}

function UsedTools(i, k) {
  return (
    <li
      key={k}
      className={`toolsItem ${i}`}
    >
      {i}
    </li>
  );
}
function JobMissions(i, k) {
  return (
    <li
      key={k}
      className={`jobMission ${i}`}
    >
      {i}
    </li>
  );
}
