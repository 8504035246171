export default function PrivacyComponent() {
  return (
    <section className="privacySection">
      <h1 className="privacyTitle">Politique de Confidentialité</h1>
      <p>
        Je récupère des informations sur la navigation sur le site. Toute
        adresse IP est masquée au deuxième niveau avant son stockage.{' '}
      </p>
      <p>Toutes les données sont stockées en Europe</p>
    </section>
  );
}
