import ProfilePic from '../files/profilLinkedin.jpg';
export default function Header() {
  return (
    <header className="header">
      <div className="profilePicLinkDiv">
        <a
          href="/"
          className="profilePicLink"
        >
          <img
            src={ProfilePic}
            className="profilePic"
            alt="Arthur Pellissier"
          ></img>
        </a>
      </div>

      <nav className="navContainer">
        <ul className="navList">
          <a
            href="/projects"
            className="navListItem"
          >
            <li>Projets</li>
          </a>
          <a
            href="/experiences"
            className="navListItem"
          >
            <li>Expériences</li>
          </a>
          <a
            href="/education"
            className="navListItem"
          >
            <li>Formations</li>
          </a>
          <a
            href="/contact"
            className="navListItem"
          >
            <li>Contact</li>
          </a>
        </ul>
      </nav>
    </header>
  );
}
