import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homepage.jsx';
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import ProjectsPage from './pages/projectspage.jsx';
import ExperiencePage from './pages/experiencespage.jsx';
import EducationPage from './pages/educationpage.jsx';
import ProjectPage from './pages/projectpage.jsx';
import ContactPage from './pages/contactpage.jsx';
import NotFoundPage from './pages/404page.jsx';
import './styles/App.css';
import './styles/tablet.css';
import './styles/mobile.css';
import PrivacyPage from './pages/privacy.jsx';
import { Helmet } from 'react-helmet';
function App() {
  return (
    <Router>
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content={`default-src 'self' https://kit.fontawesome.com/ https://entalimay.containers.piwik.pro https://entalimay.piwik.pro/ 'nonce-${NONCE}'`}
        />
      </Helmet>
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/projects"
            element={<ProjectsPage />}
          />
          <Route
            path="/experiences"
            element={<ExperiencePage />}
          />
          <Route
            path="/education"
            element={<EducationPage />}
          />
          <Route
            path="/contact"
            element={<ContactPage />}
          />
          <Route
            path="/project/:id"
            element={<ProjectPage />}
          />
          <Route
            path="/privacy"
            element={<PrivacyPage />}
          />
          <Route
            path="/*"
            element={<NotFoundPage />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
function GenerateRandomNonce(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const NONCE = GenerateRandomNonce(10);
