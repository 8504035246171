import FetchImages from './fetchimages';

export default function ShowProjectItem(i, k, m) {
  const projectImg = [];
  m.map((product, index) => FetchImages(product, projectImg));

  return (
    <a
      key={k}
      href={`/project/${i.id}`}
      className="projectSection"
    >
      {' '}
      <section className="projectSection">
        {' '}
        <h2 className="projectTitle">{i.name}</h2>
        <img
          className="galleryImg"
          src={projectImg[k]}
          alt={i.name}
        ></img>
        <p>{i.projectDesc}</p>
      </section>
    </a>
  );
}

// export function showProjectIframe(i, k, j) {
//   console.log(i);
//   return (
//     <section
//       className="projectSection"
//       key={k}
//     >
//       <a
//         href={j}
//         target="_blank"
//       >
//         {' '}
//         <h2 className="projectTitle">{i}</h2>
//       </a>
//       <iframe
//         className="projectIframe"
//         src={j}
//         allowtransparency="true"
//       ></iframe>
//     </section>
//   );
// }
